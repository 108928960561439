/* From https://css.glass */
.glass-container {
  /* From https://css.glass */
  background: rgba(170, 164, 255, 0.18);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(10.9px);
  -webkit-backdrop-filter: blur(10.9px);
  border: 1px solid rgba(170, 164, 255, 0.3);
}
.inter {
  font-family: "Inter", sans-serif;
}
.cloud {
  font-family: "cloud";
}

.atom-nucleus {
  background-color: #38b6ff;
  border-radius: 50%;
  margin-top: -50px;
  margin-left: -50px;
  left: 50%;
  height: 1px;
  position: absolute;
  top: 50%;
  width: 1px;
}

.electron {
  background-color: #38b6ff;
  border-radius: 50px;
  margin-left: -25px;
  margin-top: -25px;
  height: 50px;
  left: 50%;
  position: absolute;
  top: 0%;
  width: 50px;
}
.electron2 {
  background-color: #1c455d;
  border-radius: 50px;
  margin-left: -200px;
  margin-top: -2px;
  height: 50px;
  left: 50%;
  position: absolute;
  top: 0%;
  width: 50px;
}
.electron3 {
  background-color: #a1becf;
  border-radius: 50px;
  margin-left: -150px;
  margin-top: -100px;
  height: 50px;
  left: 50%;
  position: absolute;
  top: 0%;
  width: 50px;
}

.atom-orbit {
  border: 4px solid #38b6ff;
  border-radius: 50%;
  left: 90%;
  top: 90%;
  margin-top: -125px; /* half of the height */
  margin-left: -125px; /* half of the width */
  position: absolute;
  width: 250px;
  height: 250px;
  -webkit-animation: spin-right 20s linear infinite;
  -moz-animation: spin-right 20s linear infinite;
  -ms-animation: spin-right 20s linear infinite;
  -o-animation: spin-right 20s linear infinite;
  animation: spin-right 20s linear infinite;
}
.atom-orbit2 {
  border: 4px solid #38b6ff;
  border-radius: 50%;
  left: 90%;
  top: 90%;
  margin-top: -250px; /* half of the height */
  margin-left: -250px; /* half of the width */
  position: absolute;
  width: 500px;
  height: 500px;
  -webkit-animation: spin-right 20s linear infinite;
  -moz-animation: spin-right 20s linear infinite;
  -ms-animation: spin-right 20s linear infinite;
  -o-animation: spin-right 20s linear infinite;
  animation: spin-right 20s linear infinite;
}
.atom-orbit3 {
  border: 4px solid #38b6ff;
  border-radius: 50%;
  left: 90%;
  top: 90%;
  margin-top: -250px; /* half of the height */
  margin-left: -250px; /* half of the width */
  position: absolute;
  width: 750px;
  height: 750px;
  -webkit-animation: spin-right 20s linear infinite;
  -moz-animation: spin-right 20s linear infinite;
  -ms-animation: spin-right 20s linear infinite;
  -o-animation: spin-right 20s linear infinite;
  animation: spin-right 20s linear infinite;
}

@keyframes spin-right {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
