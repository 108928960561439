.bungee-spice {
  font-family: "Bungee Spice", sans-serif;
}
.silk-screen {
  font-family: "Silkscreen", sans-serif;
}
.comic-sans {
  font-family: "Comic Sans MS", "Comic Sans", cursive;
  font-weight: bold;
}
.inter-font {
  font-family: "Inter", sans-serif;
}
.kalam-font {
  font-family: "Kalam", cursive;
}
