.no-select {
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
}
.orb-container {
  position: absolute;
  opacity: 0.5; /* default opacity for non-current users */
  transition: opacity 0.5s ease; /* smooth transition for opacity change */
}
.orb-container p {
  opacity: 0;
}
.orb-container.is-current-user {
  opacity: 1; /* full opacity for current user */
}

.orb-container:hover,
.orb-container:hover p,
.orb-container:hover img {
  opacity: 1; /* Full opacity on hover for all elements in the orb */
}

/* Keyframe animations for floating effect */
@keyframes float1 {
  0%,
  100% {
    transform: translate(0, -7px);
  }
  50% {
    transform: translate(10px, 7px);
  }
}

@keyframes float2 {
  0%,
  100% {
    transform: translate(0, 2px);
  }
  50% {
    transform: translate(-5px, -5px);
  }
}

/* Class for orbs with floating effect */
.orb-float {
  animation-duration: 10s; /* duration of the animation */
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite; /* repeat forever */
  animation-fill-mode: forwards;
}

/* Assign different keyframe animations for variation */
.orb-float:nth-child(odd) {
  animation-name: float1;
}
.orb-float:nth-child(even) {
  animation-name: float2;
}
.orb-selected-user,
.orb-selected-user p {
  opacity: 1; /* Full opacity */
  animation: none; /* Stop floating animation */
  font-weight: bold;
}
.orbit-wall-modal-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%; /* Adjust as needed */
  height: 78%; /* Adjust as needed */
  max-height: 750px;
  background: #fff;
  border-radius: 16px;
  overflow: hidden;
}

.orbit-wall-modal-overlay-style {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10000;
}
