/* Custom scrollbar styles */
.scrollbar-custom {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgba(182, 244, 239, 0) transparent; /* Initial state for Firefox: thumb and track color */
}

.scrollbar-custom:hover {
  scrollbar-color: rgba(132, 195, 190, 0.5) transparent; /* For Firefox: thumb and track color when hovered */
}

/* For Webkit browsers */
.scrollbar-custom::-webkit-scrollbar {
  width: 8px; /* Initial state width of the scrollbar */
  height: 8px; /* Initial state height of the scrollbar */
  opacity: 0; /* Initial state: scrollbar is transparent */
  transition: opacity 0.3s; /* Transition for smooth fading */
}

.scrollbar-custom:hover::-webkit-scrollbar {
  opacity: 1; /* When hovered, scrollbar becomes visible */
}

.scrollbar-custom::-webkit-scrollbar-track {
  background: transparent; /* Track color */
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2); /* Thumb color */
  border-radius: 4px; /* Rounded corners for thumb */
  border: 1px solid transparent; /* Creates padding around the thumb */
}

.scrollbar-custom::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5); /* Thumb color */
}
