.noti-modal-center {
  position: absolute;
  font-family: "Inter", sans-serif;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%; /* Adjust as needed */
  max-width: 650px; /* Max width value */
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
}

.noti-overlay-style {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.font-provider {
  font-family: "Inter", sans-serif;
}
