.bungee-spice {
  font-family: "Bungee", sans-serif;
}

.inter-font {
  font-family: "Inter", sans-serif;
}

.kalam-font {
  font-family: "Kalam", cursive;
}

.silk-screen {
  font-family: "Silkscreen", sans-serif;
}
