.grooves-v {
  mask-image: repeating-linear-gradient(
    to right,
    black 0px,
    black 1px,
    transparent 3px,
    transparent 6px
  );
}
.grooves-h {
  mask-image: repeating-linear-gradient(
    to bottom,
    black 0px,
    black 1px,
    transparent 3px,
    transparent 6px
  );
}
.diffusion-v {
  mask-image: repeating-linear-gradient(
    to right,
    transparent 0px,
    transparent 3px,
    black 4px,
    black 5px,
    transparent 6px
  );
}
