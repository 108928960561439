/* orbitBar.css */
.slider-container {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
  width: 100%;
  display: flex;
  justify-content: center;
}

.slider-container.show {
  max-height: 50px; /* Adjust as needed for your slider's height */
}

.orbit-helper-bar {
  padding: 2px; /* Adjust padding to maintain consistent padding */
}
