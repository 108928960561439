@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 8px #ea4c89, inset 0 0 8px #ea4c89;
  }
  50% {
    box-shadow: 0 0 16px #ea4c89, inset 0 0 14px #ea4c89;
  }
  100% {
    box-shadow: 0 0 8px #ea4c89, inset 0 0 8px #ea4c89;
  }
}

.inner {
  background-color: transparent;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  box-shadow: 0 0 8px #ea4c89, inset 0 0 8px #ea4c89;
  -webkit-animation: pulse 2s linear 1s infinite;
}
.inner p {
  display: block;
  text-align: center;
  line-height: 100px;
  font-family: sans-serif;
  font-weight: 100;
  font-size: 24px;
  color: #ea4c89;
  text-shadow: 0 0 4px #ea4c89;
}
.sortable-header {
  cursor: pointer;
  position: relative;
}
.modal-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%; /* Adjust as needed */
  background: #f4f4f4;
  /* padding: 20px; */
  border-radius: 20px;
  overflow: hidden;
}

.overlay-style {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

/* Additional styles for SearchResults to make it scrollable */
.search-results {
  max-height: 300px; /* Adjust based on your needs */
  overflow-y: auto;
}

.footer-style {
  font-family: "Inter", sans-serif;
}
