.inter {
  font-family: "Inter", sans-serif;
}

.no-select2 {
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
}
.orb-container2 {
  position: absolute;
  opacity: 0.5; /* default opacity for non-current users */
  transition: opacity 0.5s ease; /* smooth transition for opacity change */
}
.orb-container2 p {
  opacity: 0;
}
.orb-container.is-current-user2 {
  opacity: 1; /* full opacity for current user */
}

.orb-container2:hover,
.orb-container2:hover p,
.orb-container2:hover img {
  opacity: 1; /* Full opacity on hover for all elements in the orb */
}

/* Keyframe animations for floating effect */
@keyframes float12 {
  0%,
  100% {
    transform: translate(0, -7px);
  }
  50% {
    transform: translate(10px, 7px);
  }
}

@keyframes float22 {
  0%,
  100% {
    transform: translate(0, 2px);
  }
  50% {
    transform: translate(-5px, -5px);
  }
}

/* Class for orbs with floating effect */
.orb-float2 {
  animation-duration: 10s; /* duration of the animation */
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite; /* repeat forever */
  animation-fill-mode: forwards;
}

/* Assign different keyframe animations for variation */
.orb-float2:nth-child(odd) {
  animation-name: float1;
}
.orb-float2:nth-child(even) {
  animation-name: float2;
}
.orb-selected-user2,
.orb-selected-user2 p {
  opacity: 1; /* Full opacity */
  animation: none; /* Stop floating animation */
  font-weight: bold;
}
