.primary-bg {
  background-color: rgb(7, 89, 133);
  background-image: radial-gradient(
      at 21% 5%,
      rgb(250, 232, 255) 0,
      transparent 41%
    ),
    radial-gradient(at 92% 93%, rgb(212, 212, 212) 0, transparent 94%),
    radial-gradient(at 18% 67%, rgb(217, 249, 157) 0, transparent 68%),
    radial-gradient(at 85% 64%, rgb(243, 232, 255) 0, transparent 93%),
    radial-gradient(at 49% 92%, rgb(199, 210, 254) 0, transparent 68%),
    radial-gradient(at 83% 74%, rgb(147, 197, 253) 0, transparent 98%);
}
