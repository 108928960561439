.app-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  overflow: hidden;
  display: flex; /* Ensures flexbox properties for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.onboarding-overlay {
  position: absolute; /* Or you can use 'relative' if absolute positioning causes issues */
  z-index: 10; /* Ensure it's above the canvas */
  width: 50%; /* Adjust as needed */
  height: 60%; /* Adjust as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}
