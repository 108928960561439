/* Keyframe animations for a smoother, more realistic floating effect with rotation */
@keyframes ufo_float {
  0%,
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
  10% {
    transform: translate(-2px, -1px) rotate(-2deg);
  }
  20% {
    transform: translate(4px, -2px) rotate(2deg);
  }
  30% {
    transform: translate(-6px, 0px) rotate(-2deg);
  }
  40% {
    transform: translate(8px, 1px) rotate(2deg);
  }
  50% {
    transform: translate(-10px, 2px) rotate(-2deg);
  }
  60% {
    transform: translate(6px, 1px) rotate(2deg);
  }
  70% {
    transform: translate(-4px, 0px) rotate(-2deg);
  }
  80% {
    transform: translate(2px, -1px) rotate(2deg);
  }
  90% {
    transform: translate(-1px, -2px) rotate(-2deg);
  }
}
@keyframes ufo_float_big {
  0%,
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
  10% {
    transform: translate(-2px, -1px) rotate(-2deg);
  }
  20% {
    transform: translate(2px, -2px) rotate(2deg);
  }
  30% {
    transform: translate(-2px, 0px) rotate(-2deg);
  }
  40% {
    transform: translate(3px, 1px) rotate(2deg);
  }
  50% {
    transform: translate(-3px, 2px) rotate(-2deg);
  }
  60% {
    transform: translate(6px, 1px) rotate(2deg);
  }
  70% {
    transform: translate(-4px, 0px) rotate(-2deg);
  }
  80% {
    transform: translate(2px, -1px) rotate(2deg);
  }
  90% {
    transform: translate(-1px, -2px) rotate(-2deg);
  }
}
@keyframes toly_float {
  0% {
    transform: translateX(-100vw, -1px) rotate(360);
    scale: 0.01;
  }
  10% {
    transform: translate(-80vw, -1px) rotate(40deg);
    scale: 0.7;
  }
  20% {
    transform: translate(-60vw, -2px) rotate(80deg);
    scale: 1;
  }
  30% {
    transform: translate(-40vw, 0px) rotate(120deg);
  }
  40% {
    transform: translate(-20vw, 1px) rotate(160deg);
  }
  50% {
    transform: translate(0vw, 2px) rotate(180deg);
  }
  60% {
    transform: translate(20vw, 1px) rotate(200deg);
  }
  70% {
    transform: translate(40vw, 0px) rotate(240deg);
  }
  80% {
    transform: translate(60vw, 0px) rotate(280deg);
  }
  90% {
    transform: translate(70vw, 0px) rotate(320deg);
  }
  100% {
    transform: translate(80vw, -1px) rotate(200deg);
    scale: 0.01;
  }
}

/* Class for orbs with a smoother floating and rotating effect */
.ufo-float {
  animation-duration: 8s; /* duration of the animation */
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite; /* repeat forever */
  animation-fill-mode: forwards;
  animation-name: ufo_float;
}
.landing-ufo-float {
  animation-duration: 15s; /* duration of the animation */
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite; /* repeat forever */
  animation-fill-mode: forwards;
  animation-name: ufo_float_big;
}
/* Class for orbs with a smoother floating and rotating effect */
/* Class for orbs with a smoother floating and rotating effect */
.flower-float {
  animation-duration: 18s; /* duration of the animation */
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite; /* repeat forever */
  animation-fill-mode: forwards;
  animation-name: ufo_float;
}
/* Class for orbs with a smoother floating and rotating effect */
.toly-float {
  animation-duration: 30s; /* duration of the animation */
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite; /* repeat forever */
  animation-fill-mode: none;
  animation-name: toly_float;
}
