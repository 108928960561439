.wall-modal-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%; /* Adjust as needed */
  height: 65%; /* Adjust as needed */
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  overflow: hidden;
}

.wall-modal-overlay-style {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10000;
}
